APP.hotNews = function () {
	// $('.slick--hot-news').slick({
	// 	infinite: true,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	centerMode: false,
	// 	responsive: [
	// 		{
	// 			breakpoint: 992,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				slidesToScroll: 1,
	// 				centerMode: true,
	// 			},
	// 		},
	// 	],
	// })
}
