APP.init = function () {
	SmoothScroll({
		animationTime: 400,
		accelerationDelta: 20,
		accelerationMax: 1,
	})

	APP.device()
	APP.header()
	APP.scrollTop()
	APP.modal()
	APP.hotNews()
	APP.expert()
	APP.testimonial()
	APP.spritespin()
	APP.collapse('.collapse', {
		isOpen: true,
	})
	APP.news()

	APP.preloader({
		onComplete() {
			AOS.init({
				duration: 700,
			})
		},
	})
}
