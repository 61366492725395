APP.spritespin = function () {
	function init() {
		if ($('.spritespin1').length > 0) {
			$('.spritespin1').spritespin({
				source: SpriteSpin.sourceArray('../public/images/baby1/img_{frame}.png', {
					frame: [1, 25],
					digits: 5,
				}),
				width: 445,
				height: 438,
				sense: -1,
				responsive: true,
			})
		}

		if ($('.spritespin2').length > 0) {
			$('.spritespin2').spritespin({
				source: SpriteSpin.sourceArray('../public/images/baby2/img_{frame}.png', {
					frame: [1, 25],
					digits: 5,
				}),
				width: 462,
				height: 538,
				sense: -1,
				responsive: true,
			})
		}

		if ($('.spritespin3').length > 0) {
			$('.spritespin3').spritespin({
				source: SpriteSpin.sourceArray('../public/images/baby3/img_{frame}.png', {
					frame: [1, 25],
					digits: 5,
				}),
				width: 717,
				height: 602,
				sense: -1,
				responsive: true,
			})
		}
	}

	init()
}
