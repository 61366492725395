APP.scrollTop = function () {
	const scrollTop = $('.scroll-top')
	const scrollTopAnimate = scrollTop.find('.animate__pulse')

	function init() {
		detectScrollTop()
	}

	function detectScrollTop() {
		WINDOW.scroll(function () {
			if ($(this).scrollTop() > 100) {
				scrollTop.addClass(CLASS._show)
				scrollTopAnimate.addClass('animate__animated')
			} else {
				scrollTop.removeClass(CLASS._show)
				scrollTopAnimate.removeClass('animate__animated')
			}
		})
	}

	init()
}
