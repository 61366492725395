APP.news = function () {
	const filter = $('.filter')
	const btnSearch = filter.find('.btn-search')
	const inputSearch = filter.find('.form__control--search')
	const sortSelect = filter.find('[name="sort"]')

	function init() {
		setFilter()

		btnSearch.on('click', function (evt) {
			const value = inputSearch.val().trim()
			if (value === '') evt.preventDefault()
		})

		sortSelect.on('change', function () {
			$('.form').submit()
		})

		$('.social__link').click(function (evt) {
			evt.preventDefault()
			navigator.clipboard.writeText($(this).attr('href'))
			$(this).find('span').css({ opacity: 1 })
			setTimeout(() => {
				$(this).find('span').css({ opacity: 0 })
			}, 1000)
		})
	}

	function setFilter() {
		const sort = APP.getUrlParameter('sort')
		const search = APP.getUrlParameter('search')
		if (sort) sortSelect.val(sort)
		if (search) inputSearch.val(search)
	}

	init()
}
