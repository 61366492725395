APP.header = function () {
	const header = $('.header')
	const burger = header.find('.burger')

	function init() {
		detectNavbarScroll()
		WINDOW.scroll(detectNavbarScroll)
		burger.on('click', handleClickBurger)
	}

	function handleClickBurger() {
		header.toggleClass(CLASS._show)
		BODY.toggleClass(CLASS._noscroll)
	}

	function detectNavbarScroll() {
		if (WINDOW.scrollTop() > 20) {
			header.addClass(CLASS._sticky)
		} else {
			header.removeClass(CLASS._sticky)
		}
	}

	init()
}
